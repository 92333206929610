
export default {
    name: 'caseList',
    components: {},
    props: {
        menuType: {
            type: String,
            default: '99'
        },
        menuTypeNum: {
            type: Number,
            default: 15
        },
        navFormShow: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            jdDutyOptions: [
                { value: true, label: '是' },
                { value: false, label: '否' }
            ],
            searchFold: false,
            caseStep: '32',
            myPagingLoading: true,
            dialogVisible: false,
            handleStatus: 1,
            userForm: {
                userId: ''
            },
            orders: [],
            navForm: {
                caseNo: '',
                insuranceNo: '',
                isPreReport: null,
                isJdDuty: '',
                reportNo: '',
                reporter: '',
                handlerId: '',
                startAccTime: '',
                endAccTime: '',
                startReportTime: '',
                endReportTime: '',
                startInflowTime: '',
                endInflowTime: '',
                startCaseEndTime: '',
                endCaseEndTime: '',
                caseStep: '',
                parentCaseStep: '',
                startWaybillTime: '',
                endWaybillTime: ''
            },
            workOrderDetail: {
                damageWorkOrderList: []
            },
            userData: [],
            totalAmount: 0,
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            multipleSelection: [],
            caseStepOption: [],
            caseStepOption2: [],
            caseEndStepOption: [],
            fetchCaseStepFlow: [],
            userTableData: [],
            tabPaneObj: {
                30: [
                    {
                        label: '待审核',
                        name: 'first',
                        code: '30'
                    },
                    {
                        label: '被驳回',
                        name: 'second',
                        code: '32'
                    },
                    {
                        label: '所有',
                        name: 'third',
                        code: '8999999'
                    }
                ],
                40: [
                    {
                        label: '查勘',
                        name: 'zero',
                        code: '402'
                    },
                    {
                        label: '初次定损',
                        name: 'first',
                        code: '40'
                    },
                    {
                        label: '重新定损',
                        name: 'first1',
                        code: '401'
                    },
                    {
                        label: '核损中',
                        name: 'second',
                        code: '41'
                    },
                    {
                        label: '待核赔',
                        name: 'second',
                        code: '49'
                    },
                    {
                        label: '所有',
                        name: 'third',
                        code: '8999999'
                    }
                ],
                50: [
                    {
                        label: '待核赔',
                        name: 'first',
                        code: '50'
                    },
                    {
                        label: '核赔完成',
                        name: 'second',
                        code: '59'
                    },
                    {
                        label: '所有',
                        name: 'third',
                        code: '8999999'
                    }
                ],
                15: [
                    {
                        label: '预定损',
                        name: 'first',
                        code: '45'
                    },
                    {
                        label: '已完成',
                        name: 'second',
                        code: '75'
                    },
                    {
                        label: '所有',
                        name: 'third',
                        code: '8999999'
                    }
                ]
            },
            tableHeight: null,
            queryFromCondition: {
                15: {},
                40: {},
                50: {}
            },
            queryFromPage: {
                15: {},
                40: {},
                50: {}
            },
            showPagination: false,
            batchFlag: false,
            autoShowDetail: false,
            caseStepOption1: [],
            surveyOptions: [
                {
                    value: true,
                    label: '是'
                },
                {
                    value: false,
                    label: '否'
                }
            ],
            surveyVal: '',
            tmpUrlList: ['/workbench/forecastCase', '/workbench/reviewAudit', '/workbench/acceptReport'],
            startInflowTime: '',
            endInflowTime: ''
        };
    },
    mounted() {
        // 动态计算table高度
        window.onresize = () => {
            this.calcHeight();
        };
        this.calcHeight();
        this.getUserList();
    },
    methods: {
        foldQuery() {
            this.searchFold = !this.searchFold;
            this.$nextTick(() => {
                this.calcHeight(true);
            });
        },
        handleInput(e, inputValueStr) {
            this.navForm[inputValueStr] = e;
            this.$forceUpdate();
        },
        copyText(text, tooltipStr) {
            let textarea = document.createElement('textarea'); //创建临时元素
            textarea.value = text; //设置要复制的内容
            document.body.appendChild(textarea); //将该元素添加到页面上
            textarea.select(); //选中输入框中的内容
            try {
                let successful = document.execCommand('copy'); //执行复制命令
                if (successful) {
                    this.$message.success(`${tooltipStr}复制成功！`);
                } else {
                    console.log('无法复制！');
                }
            } catch (err) {
                console.error('无法复制！', err);
            } finally {
                document.body.removeChild(textarea); //移除临时元素
            }
        },
        // 存储查询数据
        storageQueryFrom(formData) {
            let tmpOtherData = {};
            let isSaveQuery = !this.$route.query.caseNo && (!this.$route.query.query || this.$route.query.query == 'null');
            if (formData && isSaveQuery) {
                for (const key in formData) {
                    if (key != 'condition') {
                        tmpOtherData[key] = formData[key];
                    }
                }
                if (this.tmpUrlList.indexOf(this.$route.path) != -1) {
                    localStorage.setItem('formCondition' + this.menuTypeNum, JSON.stringify(this.navForm));
                    localStorage.setItem('formPage' + this.menuTypeNum, JSON.stringify(tmpOtherData));
                } else {
                    this.queryFromCondition[this.menuTypeNum] = formData.condition;

                    this.queryFromPage[this.menuTypeNum] = tmpOtherData;
                    localStorage.setItem('queryFromCondition', JSON.stringify(this.queryFromCondition));
                    localStorage.setItem('queryFromPage', JSON.stringify(this.queryFromPage));
                }
            }
        },
        calcHeight(foldFlag) {
            if (!this.navFormShow) {
                return;
            }
            let elRadioGroup = document.querySelector('.el-radio-group') ? document.querySelector('.el-radio-group').clientHeight : 0;
            // 顶部tabs
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            // 搜索筛选项
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            // 分页
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 0;
            let guHeight = null;

            switch (this.menuType) {
                case 'searchPage':
                    guHeight = 24 + 20 + 60 + 52;
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
                case 'staging':
                    guHeight = 24 + 20 + 60 + 96;
                    this.tableHeight = window.innerHeight - elRadioGroup - registerTabsHeight - publicBoxHeight - tableHeaderHeight - guHeight;
                    break;

                case '15':
                    guHeight = 24 + 20 + 60 + 84;
                    this.tableHeight = window.innerHeight - elRadioGroup - registerTabsHeight - publicBoxHeight - tableHeaderHeight - guHeight;
                    break;
                case '40':
                    guHeight = 24 + 20 + 60 + 90;
                    this.tableHeight = window.innerHeight - elRadioGroup - registerTabsHeight - publicBoxHeight - tableHeaderHeight - guHeight;
                    break;

                default:
                    if (this.searchFold) {
                        guHeight = 24 + 20 + 60 + 54;
                    } else {
                        if (foldFlag) {
                            guHeight = 24 + 20 + 60 + 54;
                        } else {
                            guHeight = 24 + 20 + 60 + 84;
                        }
                    }
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
            }
            this.tableHeight = this.tableHeight < 200 ? 200 : this.tableHeight;
            this.$forceUpdate();
        },
        caseStepChange() {
            this.$nextTick(() => {
                this.$refs.filterTable && this.$refs.filterTable.doLayout();
            });
        },
        // 根据不同参数调用不同接口
        parameterChanges() {
            // 获取存储查询数据start
            let tmpCurrentFormCondition;
            let tmpCurrentFormPage;
            if (this.tmpUrlList.indexOf(this.$route.path) != -1) {
                // localStorage.getItem('form' + val, JSON.stringify(this.navForm));
            } else if (!this.$route.query.caseNo && (!this.$route.query.query || this.$route.query.query == 'null')) {
                this.queryFromCondition = localStorage.getItem('queryFromCondition') ? JSON.parse(localStorage.getItem('queryFromCondition')) : this.queryFromCondition;
                this.queryFromPage = localStorage.getItem('queryFromPage') ? JSON.parse(localStorage.getItem('queryFromPage')) : this.queryFromPage;

                tmpCurrentFormCondition = this.queryFromCondition[this.menuTypeNum] || null;

                tmpCurrentFormPage = this.queryFromPage[this.menuTypeNum] || null;
                if (tmpCurrentFormCondition && Object.keys(tmpCurrentFormCondition).length > 0) {
                    this.navForm = tmpCurrentFormCondition;
                }

                if (tmpCurrentFormPage && Object.keys(tmpCurrentFormPage).length > 0) {
                    this.currentPage = tmpCurrentFormPage.pageNo;
                    this.pageSize = tmpCurrentFormPage.pageSize;
                    this.orders = tmpCurrentFormPage.orders;
                }
            }
            // end
            this.menuType != 'searchPage' && this.menuType != 'staging' && (this.navForm.caseStep = this.menuType);

            switch (this.menuType) {
                case 'staging':
                    this.navForm.parentCaseStep = this.menuTypeNum;

                    // 回显案件列表二级tab start
                    let storageCaseStep = localStorage.getItem('tmpCaseStep');
                    switch (this.menuTypeNum) {
                        case 15:
                            if ((tmpCurrentFormCondition && !tmpCurrentFormCondition.caseStep && storageCaseStep != '8999999') || !storageCaseStep) {
                                this.navForm.caseStep = this.menuTypeNum != 50 && this.tabPaneObj[this.menuTypeNum] && this.tabPaneObj[this.menuTypeNum][0] ? this.tabPaneObj[this.menuTypeNum][0].code : '';
                            } else if (this.$route.query && this.$route.query.caseStep) {
                                this.navForm.caseStep = this.$route.query.caseStep;
                            } else if (storageCaseStep) {
                                this.navForm.caseStep = storageCaseStep;
                            }
                            break;
                        case 40:
                            if ((tmpCurrentFormCondition && !tmpCurrentFormCondition.caseStep && storageCaseStep != '8999999') || !storageCaseStep) {
                                this.navForm.caseStep = this.menuTypeNum != 50 && this.tabPaneObj[this.menuTypeNum] && this.tabPaneObj[this.menuTypeNum][0] ? this.tabPaneObj[this.menuTypeNum][0].code : '';
                            } else if (storageCaseStep) {
                                this.navForm.caseStep = storageCaseStep;
                            }
                            break;

                        default:
                            this.navForm.caseStep = this.menuTypeNum != 50 && this.tabPaneObj[this.menuTypeNum] && this.tabPaneObj[this.menuTypeNum][0] ? this.tabPaneObj[this.menuTypeNum][0].code : '';
                            break;
                    }
                    // end

                    // 领取查勘案件跳转查勘tab
                    if (this.$route.query && this.$route.query.tabTypeStr) {
                        this.navForm.caseStep = this.tabPaneObj[this.menuTypeNum][0].code;
                    } else if (this.$route.query && this.$route.query.menuType == 40) {
                        this.navForm.caseStep = this.tabPaneObj[this.menuTypeNum][1].code;
                    }
                    this.radioSearch();
                    break;
                case 'searchPage':
                    this.navForm.parentCaseStep = '';
                    this.navForm.isPreReport = this.menuTypeNum == 2 ? true : false;
                    if (this.$route.query.caseNo && this.$route.query.query == 'query') {
                        this.navForm.caseNo = this.$route.query.caseNo;
                        this.autoShowDetail = true;
                    } else {
                        this.autoShowDetail = false;
                    }

                    this.getTbodyData();
                    break;

                default:
                    this.navForm.parentCaseStep = '';
                    this.getTbodyData();
                    break;
            }
        },

        setCurrentDatetime() {
            this.startInflowTime = this.getCurrentFirstDatetime();
            this.endInflowTime = this.getCurrentLastDatetime();
        },

        getCurrentFirstDatetime() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2) + ' 00:00:00';
        },

        getCurrentLastDatetime() {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2) + ' 23:59:59';
        },

        radioSearch(flag) {
            // this.calcHeight();
            this.currentPage = flag ? 1 : this.currentPage;
            this.navForm.parentCaseStep = this.menuTypeNum;
            this.getCasePage();
        },
        getUserList() {
            let jsonData = { schedulingType: 2 };
            this.instance.post('/tpa/api/case/task/searchUser', jsonData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.userTableData = data.data;
                    // todo
                    // this.handleCase({ caseNo: 'test08221928test082251' }, '', 'show');
                }
            });
        },
        //切换table选中事件
        handleSelectionChange(val, receiveFlag) {
            if (!receiveFlag) {
                let tmpCaseNoList = [];
                val.length > 0 &&
                    val.forEach((value, index) => {
                        tmpCaseNoList.push(value.caseNo);
                    });
                this.multipleSelection = tmpCaseNoList;
            } else {
                this.batchFlag = true;
                this.instance.post('/tpa/api/case/claim/batch', this.multipleSelection).then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success(`成功领取个数：${res.data.data.successCount}；领取失败个数：${res.data.data.failCount}`);
                        setTimeout(() => {
                            this.$router.push({
                                path: '/workbench/myTask',
                                query: { caseStep: 40, menuType: this.menuType }
                            });
                            this.batchFlag = false;
                        }, 3000);
                    } else {
                        this.$message.error(res.data.message);
                        if (res.data.message == '当前案件不能操作, 请稍后重试!' || res.data.message == '当前案件已经被认领') {
                            setTimeout(() => {
                                location.reload();
                                this.batchFlag = false;
                            }, 500);
                        }
                    }
                });
            }
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            if (this.menuType == 'staging') {
                this.radioSearch();
            } else {
                this.getTbodyData();
            }
        },
        //点击审核或者核赔按钮
        handleCase(row, menuTypeNum, clickType) {
            localStorage.removeItem('detailShow');
            // 案件查询查看详情页
            if (this.menuType == 'searchPage') {
                let detailStr = 0;
                // 40待定损 41核损中 42查勘 43查勘中 49 定损 -- 复用定损
                let lossPageArr = [40, 41, 42, 43, 49];
                if (lossPageArr.indexOf(row.caseStep) != -1) {
                    detailStr = 1;
                }
                // 50待核赔 70结案 90已支付 -- 复用结案
                let closeCasePageArr = [50, 70, 90];
                if (closeCasePageArr.indexOf(row.caseStep) != -1) {
                    detailStr = 2;
                }
                // 15预定损 45 75预报案 -- 复用预报案
                let forecastCasePageArr = [15, 45, 75];
                if (forecastCasePageArr.indexOf(row.caseStep) != -1) {
                    detailStr = 3;
                }

                if (clickType == 'show') {
                    localStorage.setItem('detailShow', clickType);
                }
                this.$emit('handleCase', {
                    caseNo: row.caseNo,
                    menuTypeNum: this.menuTypeNum,
                    detailStr: detailStr,
                    detailShow: 'detailShow',
                    menuType: this.menuType
                });

                return;
            } else {
                localStorage.removeItem('detailShow');
            }

            // 其他菜单跳转页面处理/详情
            // menuType != staging 跳转对应案件状态tab
            // 40 待定损 49 待理算
            if (this.menuType == 'staging') {
                let handleQuery = {
                    caseNo: row.caseNo,
                    menuTypeNum: this.menuTypeNum,
                    detailShow: clickType == 'show' ? 'detailShow' : clickType
                };
                let handleStaging = { caseNo: row.caseNo, menuTypeNum: this.menuTypeNum, clickType: clickType };
                // clickType - 'survey' 查勘处理
                if (clickType === 'handle') {
                    this.recordEventTracking(row.caseNo, row.caseStep, '进入案件作业');
                }
                this.$emit('handleCase', clickType == 'show' ? handleQuery : handleStaging);
            } else {
                this.checkedRowDetail = row;
                this.instance.post('/tpa/api/case/claim/' + row.caseNo, {}).then((res) => {
                    if (res.data.code == 200) {
                        // isSceneSurvey 查勘案件
                        if (row.caseStep == 10 && String(row.isSceneSurvey) == 'true') {
                            this.$router.push({
                                path: '/workbench/myTask',
                                query: {
                                    caseNo: row.caseNo,
                                    caseStep: res.data.data.caseStep,
                                    menuType: this.menuType,
                                    tabTypeStr: 402
                                }
                            });
                        } else {
                            this.$router.push({
                                path: '/workbench/myTask',
                                query: { caseNo: row.caseNo, caseStep: res.data.data.caseStep, menuType: this.menuType }
                            });
                        }
                    } else {
                        this.$message.error(res.data.message);

                        if (res.data.message == '当前案件不能操作, 请稍后重试!' || res.data.message == '当前案件已经被认领') {
                            setTimeout(() => {
                                location.reload();
                            }, 500);
                        }
                    }
                });
            }
        },
        //表格排序
        sortChange(column) {
            // column.ascending==上升  column.descending==下降  column.prop
            if (!column.order) {
                this.orders = [];
            } else {
                let obj = {
                    asc: column.order == 'descending' ? false : true,
                    column: column.prop
                };
                this.orders = [obj];
            }
            if (this.menuType == 'staging') {
                this.radioSearch();
            } else {
                this.getTbodyData();
            }
        },
        //重置筛选项
        resetForm() {
            this.$refs.navForm && this.$refs.navForm.resetFields();

            let workBenchKeyStrList = ['caseNo', 'insuranceNo', 'reportNo', 'reporter', 'handlerId', 'startAccTime', 'endAccTime', 'startReportTime', 'endReportTime', 'startInflowTime', 'endInflowTime', 'startCaseEndTime', 'endCaseEndTime', 'isSceneSurvey', 'isReturn'];
            let otherKeyStrList = ['caseNo', 'caseStep', 'insuranceNo', 'reportNo', 'reporter', 'handlerId', 'startAccTime', 'endAccTime', 'startReportTime', 'endReportTime', 'startInflowTime', 'endInflowTime', 'startCaseEndTime', 'endCaseEndTime', 'isSceneSurvey', 'isReturn'];
            let myWorkKeyStrList = ['caseNo', 'insuranceNo', 'reportNo', 'reporter', 'handlerId', 'startAccTime', 'endAccTime', 'startReportTime', 'endReportTime', 'startInflowTime', 'endInflowTime', 'startCaseEndTime', 'endCaseEndTime', 'isSceneSurvey', 'isReturn'];

            // menuTypeNum
            for (const key in this.navForm) {
                if ((this.menuType == 15 || this.menuType == 40 || this.menuType == 49) && workBenchKeyStrList.indexOf(key) != -1) {
                    this.navForm[key] = null;
                } else if (this.menuType == 'staging' && myWorkKeyStrList.indexOf(key) != -1) {
                    this.navForm[key] = null;
                } else if (this.menuType == 'searchPage' && otherKeyStrList.indexOf(key) != -1) {
                    this.navForm[key] = null;
                }
            }
            this.$forceUpdate();
        },
        //获取tbody里的数据
        getTbodyData() {
            let json = {};
            if (this.navFormShow) {
                var navForm = JSON.parse(JSON.stringify(this.navForm));
                json.condition = navForm;
                json.orders = this.orders;
                json.pageNo = this.currentPage;
                json.pageSize = this.pageSize;
                if (json.condition.caseStep == '40' || json.condition.caseStep == '49') {
                    json.condition.permissionCaseShow = true;
                } else {
                    json.condition.permissionCaseShow = false;
                }
                json.condition.isWorkbench = this.menuType == 15 || this.menuType == 40 || this.menuType == 49 ? true : null;
                // 存储查询数据
                this.storageQueryFrom(json);
            } else {
                json.condition = {
                    caseNo: '',
                    insuranceNo: '',
                    isPreReport: null,
                    isJdDuty: '',
                    reportNo: '',
                    reporter: '',
                    handlerId: '',
                    startAccTime: '',
                    endAccTime: '',
                    startReportTime: '',
                    endReportTime: '',
                    startInflowTime: '',
                    endInflowTime: '',
                    startCaseEndTime: '',
                    endCaseEndTime: '',
                    caseStep: '',
                    parentCaseStep: '',
                    isWorkbench: null
                };
                json.pageNo = this.currentPage;
                json.pageSize = this.pageSize;
                if (this.menuType === '15') {
                    json.condition.caseStep = 15;
                }
                if (this.menuType === '40') {
                    json.condition.caseStep = 40;
                }
                if (this.menuType === '49') {
                    json.condition.caseStep = 49;
                }
                json.condition.isWorkbench = this.menuType === '15' || this.menuType === '40' || this.menuType === '49' ? true : null;
                if (this.startInflowTime && this.startInflowTime !== '') {
                    json.condition.startInflowTime = this.startInflowTime;
                }
                if (this.endInflowTime && this.endInflowTime !== '') {
                    json.condition.endInflowTime = this.endInflowTime;
                }
            }

            this.tableData = [];
            this.myPagingLoading = true;
            this.instance.post('/tpa/api/case/paging', json).then((res) => {
                this.myPagingLoading = false;
                if (res.data.code == 200 && res.data.data) {
                    this.tableData = res.data.data.list || [];
                    this.totalPage = parseInt(res.data.data.total);
                    // this.showPagination = false;

                    if (this.tableData.length == 0 && res.data.data.current > 1 && this.currentPage > 1) {
                        if (res.data.data.total > 1) {
                            this.currentPage -= 1;
                        } else {
                            this.currentPage = 1;
                        }
                        this.getTbodyData();
                    }

                    this.$nextTick(() => {
                        this.showPagination = true;
                        this.$refs.filterTable && this.$refs.filterTable.doLayout();
                    });
                    this.calcHeight();
                    setTimeout(() => {
                        this.autoShowDetail && this.$refs.handleCase && this.$refs.handleCase.click();
                        this.$route.query.query = 'null';
                    }, 500);
                } else {
                    this.totalPage = 0;
                }
            });
        },
        //获取认领案件列表
        getCasePage(sign, activeFlag) {
            let tmpCaseStep = this.navForm.caseStep;
            if (activeFlag) {
                this.activeName = 'first';
                let tmpTabData = this.tabPaneObj[this.navForm.parentCaseStep];
                let tmpIndex = tmpTabData.findIndex((item) => {
                    return item.name == this.activeName;
                });
                tmpCaseStep = tmpTabData[tmpIndex].code ? tmpTabData[tmpIndex].code : '';
            }
            let json = {};
            let navForm = JSON.parse(JSON.stringify(this.navForm));

            json.condition = navForm;
            // 42 查勘案件
            json.condition.caseStep = tmpCaseStep == '401' ? '40' : tmpCaseStep == '402' ? '42' : tmpCaseStep == '8999999' ? '' : this.navForm.caseStep;
            json.orders = this.orders;
            json.pageNo = this.currentPage;
            json.pageSize = this.pageSize;
            // 定损拆分 待定损 和 待多次定损
            json.condition.times = tmpCaseStep == '40' ? 10 : tmpCaseStep == '401' ? 20 : null;

            // 二级active
            tmpCaseStep && localStorage.setItem('tmpCaseStep', tmpCaseStep);
            // 存储查询数据
            this.storageQueryFrom(json);
            this.tableData = [];
            this.myPagingLoading = true;
            this.instance.post('/tpa/api/case/myPaging', json).then((res) => {
                this.myPagingLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.tableData = data.data.list || [];
                    this.totalPage = data.data.total;
                    this.showPagination = false;

                    if (this.tableData.length == 0 && data.data.current > 1 && this.currentPage > 1) {
                        this.currentPage -= 1;
                        this.getCasePage();
                    }

                    this.$nextTick(() => {
                        this.showPagination = true;
                        this.$refs.filterTable && this.$refs.filterTable.doLayout();
                        this.calcHeight();
                    });
                    this.$forceUpdate();

                    // setTimeout(() => {
                    //     this.calcHeight();
                    // }, 1000);
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            if (this.menuType == 'staging') {
                this.radioSearch();
            } else {
                this.getTbodyData();
            }
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val);
            if (this.menuType == 'staging') {
                this.radioSearch();
            } else {
                this.getTbodyData();
            }
        },
        //操作批量认领、批量改派
        handleBatch() {
            if (this.multipleSelection.length == 0) {
                this.$message.error('抱歉，请选择您要批量操作的保单！');
                return false;
            }
        },
        getReportCaseStepEnum() {
            this.instance.get('/tpa/api/enum/getReportCaseStepEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.caseStepOption1 = res.data.data;
                }
            });
        },
        getPreCaseStepEnum() {
            this.instance.get('/tpa/api/enum/getPreCaseStepEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.caseStepOption = res.data.data;
                }
            });
        },
        getCaseStepEnum() {
            this.instance.get('/tpa/api/enum/getCaseStepEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.caseStepOption = res.data.data;
                }
            });
        }
    },
    watch: {
        menuTypeNum: {
            handler(val) {
                this.tableData = [];
                this.myPagingLoading = true;

                switch (val) {
                    case 1:
                        this.getReportCaseStepEnum(); // 预报案状态
                        break;
                    case 2:
                        this.getPreCaseStepEnum(); // 正式案件状态
                        break;

                    default:
                        // if (val != 999) {
                        //     this.getCaseStepEnum();
                        // }
                        break;
                }
                for (const key in this.navForm) {
                    this.navForm[key] = '';
                }
                if (!this.navFormShow) {
                    this.setCurrentDatetime();
                }
                if (val) {
                    this.parameterChanges();
                }
            },
            immediate: true
        }
    }
};
